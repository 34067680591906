.ck-editor__main{
    border: none !important;
    border-top: none !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}
h1{
    font-size: 34px;
}
h2 {
    font-size: 32px;
}
h3 {
    font-size: 26px;
}
h4{
    font-size: 22px;
}
p {
    font-size: 16px;
    margin-left: 0 !important;
}
ol,ul {
    padding-left: 9px !important;
}
.ck-content{
    padding-left: 20px !important;
}