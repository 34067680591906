.ck-editor__main{
    border: none !important;
    border-top: none !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;

}
.ck-toolbar_grouping{

    border: 1px solid #012C54 !important;
    border-radius: 8px !important;
    color: '#012C54';
}
.ck-editor__editable{
    padding: 0;
    border: 1px solid #012C54 !important;
    border-radius: 8px !important;
    margin-top: 8px;
    min-height: 15vh;
}