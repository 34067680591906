

   ::-webkit-scrollbar {
    width: 4px; /* Width of vertical scrollbar */
    height: 4px; /* Height of horizontal scrollbar */
  }
  
  /* Track for scrollbar */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track */
    border-radius: 0; /* Optional: adjust as needed */
    border: none;
  }
  
  /* Handle for scrollbar */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the handle */
    border-radius: 5px; /* Roundness of the handle */
    border: none;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the handle on hover */
  }
  
  /* Position scrollbar on the left side */
  /* For Firefox and other browsers, scrollbars will be on the default (right) side */
  /* Note: Firefox does not support -webkit- prefix */
  /* Note: Safari (macOS) does not currently support placing the scrollbar on the left side */
  .scroll-container {
    overflow-y: auto; /* Enable vertical scrollbar */
    direction: rtl; /* Reverse the direction */
  }
  
  .content {
    direction: ltr;
    
  }
