.popover {
    position: relative;
    display: inline-block;
  }
  
  .button {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .popover-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 9999; /* Ensure high visibility */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .popover-content p {
    margin: 0;
  }
  