.Wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: var(--spacing);
  /* margin-bottom: -1px; */
  margin-left: 0;
  direction: ltr;
  width: 270px;
  position: relative;

  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 0;
    padding-left: 10px;
    padding-top: 5px;

    .TreeItem {
      --vertical-padding: 5px;
      
      padding-right: 24px;
      border-radius: 4px;
      box-shadow: 0px 15px 15px 0 rgba(34, 33, 81, 0.1);
    }
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .TreeItem {
        position: relative;
        padding: 0;
        height: 8px;
        border-color: #004381;
        background-color: #004381;

        &:before {
          position: absolute;
          left: -8px;
          top: -3px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #004381;
          background-color: #ffffff;
        }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .TreeItem > * {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.TreeItem {
  --vertical-padding: 10px;
  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 10px;
  background-color: #fff;
  color: #222;
  box-sizing: border-box;
  border-radius: 8px;
}

.Text {
  /* flex-grow: 1; */
  padding-left: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.Count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2389ff;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {
  .Text,
  .Count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.Collapse {
  position: absolute;
  right: 10px;
  top: 10px;
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }
}
