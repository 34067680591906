@config "../tailwind.config.js";
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Adamina&family=Roboto:wght@300;400;600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@tailwind base;
@layer base {
  html {
    @apply text-font;
  }

  body {
    @apply font-sans;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, Edge, and Opera */
}

:root {
  --base-white: #fff;
  --base-black: #000;
  --base-background: #f9f9f9;
  --secondary-background: #f1f8f9;


  --bg-blue-deep:#023464;
  --blue-oil: #012c54;
  --blue-ocean-deep: #004381;
  --blue-normal: #0973d4;
  --blue-normal-35: #0973D440;
  --blue-caribbean: #78e8ef;
  --blue-ocean-light: #f1fdfd;
  --blue-alice-blue: #f0f8ff;
  --blue-white-ice: #f1fdfd;
  --blue-blue-dark: #0973d426;
  --blue-cornflower: #0973d41f;
  --blue-light: rgba(120, 232, 239, 0.1);
  --blue-very-light: #EEF5FF;
  --blue-very-light-30: #EEF5FF4D;
  --blue-light: #78e8ef1a;
  --blue-light-100: #EEF5FF;
  --blue-onahau: #CDE7FF;
  --blue-light-200: rgba(9, 115, 212, 0.50);

  --gray-bright: #EEEEEE;
  --charcoal-dark: #333333;
  --gray-default: #f1f1f1;
  --gray-very-light: #dddddd;
  --gray-light: #dfdfdf;
  --gray-light-100: #F2F2F2;
  --gray-medium: #737373;
  --gray-dark: #403f3f;
  --gray-thin: #868686;
  --gray-thick: #e9e9e9;
  --gray-weight: #f0f0f0;
  --gray-100: #efefef;
  --gray-200: rgba(48, 119, 184, 0.1);
  --gray-300: #cacaca;

  --dark-light: #eaeaea;
  --dark: #000;

  --green-success: #0f8430;
  --green-done: #01DA3E;
  --green-light: #31CC5C;
  --green-very-light: #E5FCD6;


  --pink-light: rgba(254, 232, 232, 0.5);
  --pink-lace: #F3D4FF;
  --rose-light: #fee8e8;
  --rose-light-opacity-50: #fee8e87f;

  --orange: #FFEEEE;

  --red-accessible: #d40000;
  --red-decline: #FA1717;
  --colors-error-feedback: #ed7171;

  --yellow-normal: #FFB800;
  --yellow-light: #FFFBF2;
  --yellow-desert: #ffdb96;
  --yellow-alert: #f7c630;
  --yellow-warning: #FACA50;
  --purple: #C491D7;
  --purple-magenta: #F9E8FF;
  --purple-light-100: #FCF6FF;
  --purple-light: rgba(196, 145, 215, 0.15);
}

@tailwind components;
@tailwind utilities;

@layer utilities {
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
}

.selectEpro > div {
  border: none;
}

.selectEpro .css-t3ipsp-control {
  box-shadow: none !important;
}

.selectEpro .css-1nmdiq5-menu > div {
  background: #012c54;
  border-radius: 4px;
  text-align: left;
}

.selectEpro .css-1nmdiq5-menu > div > div {
  padding-left: 8px;
  font-size: 14px;
  color: white;
  background-color: transparent;
  height: 22px;
}

.selectEpro.selectEproRepeat > div > div:first-child {
  border-bottom: 0.5px solid #737373;
}

.selectEpro .css-1hb7zxy-IndicatorsContainer {
  margin-left: 8px;
}

.selectEpro > div > div > div {
  color: #737373;
}

.selectEpro .css-1nmdiq5-menu {
  width: 125px;
}

.selectEpro .css-1nmdiq5-menu > div > div:hover {
  background-color: #3e5f7d;
}

.selectEpro div {
  padding: 0;
}

.solids {
  position: relative;
}

.solids::before {
  content: '';
  background-image: url('../public/assets/images/geo5.png');
  position: absolute;
  top: 0;
  width: 520px;
  height: 330px;
  background-repeat: no-repeat;
  left: 22px;
  z-index: 0;
}

.solids::after {
  content: '';
  background-image: url('../public/assets/images/geo6.png');
  position: absolute;
  border: 0;
  width: 326px;
  height: 453px;
  background-repeat: no-repeat;
  right: 0;
  z-index: 0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a3a3a3;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

.two-line-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-width: 70px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  /* -ms-overflow-style: none;
scrollbar-width: none;   */
}

.no-border-mui fieldset {
  border: none !important;
}

.no-border-mui input {
  text-align: center !important;
}

.hide-arrow {
  -moz-appearance: textfield;
}

.hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.study-map .gm-bundled-control {
  right: 120px !important;
}

.study-map .gm-bundled-control > div > div {
  border-radius: 15px !important;
  background-color: var(--blue-ocean-deep) !important;
  width: 112px !important;
  height: 32px !important;
  display: flex !important;
  justify-content: end !important;
  padding: 5px 15px 0px 0px !important;
}

.study-map .gm-style-iw-ch {
  padding-top: 0 !important;
}

.study-map .gm-bundled-control > div > div > button {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--blue-ocean-deep) !important;
}

.study-map .gm-bundled-control > div > div > button:first-child img {
  display: none;
}

.study-map .gm-bundled-control > div > div > button:first-child:after {
  font-family: FontAwesome;
  content: '\f00e';
  color: white;
  font-size: 18px;
}

.study-map .gm-bundled-control > div > div > button:last-child img {
  display: none;
}

.study-map .gm-bundled-control > div > div > button:last-child:after {
  font-family: FontAwesome;
  content: '\f010';
  color: white;
  font-size: 18px;
}

.study-map .gm-bundled-control > div > div > div {
  background-color: var(--blue-ocean-deep) !important;
}

.study-map .gm-style-iw-d {
  padding: 0px !important;
  overflow: hidden !important;
}

.study-map .gm-style-iw {
  padding: 0px !important;
}

.study-map .gm-style-iw-tc,
.study-map .gm-ui-hover-effect {
  display: none !important;
}

.red-line {
  position: absolute;
  top: 0; /* Initial position */
  left: 0;
  width: 100%;
  height: 2px; /* Height of the red line */
  background-color: var(--colors-error-feedback);
}

.no-county-select .PhoneInputCountry {
  display: none;
}

.scaleSlider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3); /* Add shadow here */
}

.scaleSlider input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3); /* Add shadow here */
}
